<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @toolTap="toolTap" @addTap="addTap" />
    <listTable
      :list="list"
      :page="page"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :columns="columns"
      @page-change="pageChange" />
    <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="addColumns" :type="typeForm" @submit="submit" />
      </div>
    </el-drawer>
    <el-drawer v-model="formDrawerVisible2" size="30%" title="详情">
      <div v-if="formDrawerVisible2" style="padding-left: 16px; padding-right: 16px;">
        <formSee :columns="addColumnsDet" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { majorList, userDel, userList, userSubmit, userUpdateList } from '@/apis/common'
import listToolbar from '@/components/system/listToolbar.vue'
import listTable from '@/components/system/listTable.vue'
import Form from '@/components/system/form.vue'
import formSee from '@/components/system/formSee.vue'

export default {
  name: 'student',
  components: { formSee, Form, listTable, listToolbar },
  data() {
    return {
      loading: false,
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      page: 1,
      pageSize: 15,
      total: 0,
      toolbar: [
        {type: 'input', label: '姓名', model: 'name', value: ''},
        {type: 'input', label: '手机号', model: 'phone', value: ''},
        {type: 'button', label: '搜索', icon: 'el-icon-search', def: 'default', key: 'search'},
        {type: 'button', label: '添加', def: 'primary',key: 'add'},
      ],
      formData: {
        name: '',
        phone: ''
      },
      list: [],
      columns: [
        {label: '教师ID', prop: 'id', width: 60, type: 'text'},
        {label: '姓名', prop: 'name', width: 100, type: 'text'},
        {label: '昵称', prop: 'nickname', width: 100, type: 'text'},
        {label: '手机号', prop: 'phone', width: 100, type: 'text'},
        {label: '状态', prop: 'status', width: 80, type: 'switch', change: (e,v) => this.changeStatus(e,v)},
        {label: '专业列表', prop: 'majorList', width: 150, type: 'arr'},
        {label: '所属班级', prop: 'classList', width: 150, type: 'arr'},
        {label: '注册时间', prop: 'created_at', width: 150, type: 'text'},
        {label: '操作', width: 260, type: 'but', arr: [
            {type: 'default', icon: 'el-icon-document', label: '查看', onClick: (row) => this.seeDetails(row)},
            {type: 'primary', icon: 'el-icon-edit', label: '修改', onClick: (row) => this.modify(row)},
            {type: 'danger', icon: 'el-icon-delete', label: '删除', onClick: (row) => this.del(row)}
          ]}
      ],
      addColumns: [
        {label: '姓名', prop: 'name', type: 'input',value: ''},
        {label: '昵称', prop: 'nickname', type: 'input',value: ''},
        {label: '手机号', prop: 'phone', type: 'input',value: ''},
        {label: '专业', prop: 'major_id', type: 'selectMultiple', value: '',options: []},
        {label: '登录密码', prop: 'password', type: 'input',value: ''},
        // {label: '短信验证码', prop: 'sms_code', type: 'code',value: ''},
        // {label: '短信的秘钥', prop: 'sms_key', type: 'text',value: ''},
      ],
      formDrawerVisible: false,
      formDrawerVisible2: false,
      typeForm: 'add',
      addColumnsDet: [
        {label: '姓名', prop: 'name', type: 'input',value: ''},
        {label: '昵称', prop: 'nickname', type: 'input',value: ''},
        {label: '手机号', prop: 'phone', type: 'input',value: ''},
        {label: '专业', prop: 'majorList', type: 'arrStr', value: ''},
        {label: '所属班级', prop: 'classList',type: 'arrStr'},
        {label: '状态', prop: 'status', type: 'select', value: '',options: [
            {label: '正常', value: true},
            {label: '冻结', value: false},
          ]},
        {label: '注册时间', prop: 'created_at', type: 'input'},
      ],
      addFormData: {}
    }
  },
  mounted() {
    this.getUserList()
    this.getMajorList()
  },
  methods: {
    changeStatus(e,v) {
      userUpdateList({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        user_id: v.id,
        status: e ? 1 : 0
      }).then(res => {
      })
    },
    getMajorList() {
      majorList({
        page: 1,
        pagesize: 40,
        admin_id: this.info.id,
        is_all: 1
      }).then(res => {
        this.addColumns[3].options = res.data.data.list.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      })
    },
    submit(e,n) {
      let form = {
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        user_id: n === 'edit' ? this.addFormData.id : '',
        type: 2,
        ...e
      }
      userSubmit(form).then(res => {
        this.formDrawerVisible = false
        this.$message.success('操作成功')
        this.getPackageList()
      })
    },
    // 查看
    seeDetails(row) {
      this.addColumnsDet.forEach(item => {
        item.value = row[item.prop]
      })
      this.formDrawerVisible2 = true
    },
    // 删除
    del(row) {
      this.addFormData = row
      this.$confirm('确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        userDel({
          admin_id: this.info.id,
          school_id: this.info.agency_school_id,
          user_id: row.id
        }).then(res => {
          this.$message.success('删除成功')
          this.getPackageList()
        })
      }).catch(() => {})
    },
    // 修改
    modify(row) {
      this.addFormData = row
      this.typeForm = 'edit'
      this.addColumns.forEach(item => {
        item.value = row[item.prop]
        if(item.type === 'selectMultiple') {
          item.value = row[item.prop].split(',').map(x => parseInt(x))
        }
      })
      console.log(this.addColumns)
      this.formDrawerVisible = true
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    // 分页
    pageChange(e) {
      this.page = e
      this.getPackageList()
    },
    toolTap(data) {
      this.formData = data
      this.page = 1
      this.getUserList()
    },
    getUserList() {
      this.loading = true
      userList({
        page: this.page,
        pagesize: this.pageSize,
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        type: 2,
        ...this.formData
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.list.map(x => {
          x.status = x.status === 1
          return x
        })
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
